<template>
  <div v-if="store && token">
    <router-view :store="store"></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  created() {
    if (!this.token) {
      this.$router.push({
        name: "CheckUserCredentials",
        params: this.$route.params,
        query: { redirect: "StoreOrders", storeUrlName: this.storeUrlName },
      });
      return;
    }
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
      store: "guest/store/getStore",
    }),
    storeUrlName() {
      return this.store.storeUrlName;
    },
  },
};
</script>
